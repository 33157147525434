import React, { useEffect, useState } from 'react';
import Logo from '../img/logo/logo';

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll  = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
        setIsScrolled(false);
    }
  };

  const scroll = (event) => {
    event.preventDefault();

    const id = event.currentTarget.getAttribute('data-id');
    const targetElement = id ? document.getElementById(id) : null;
    if (targetElement) {
        targetElement.scrollIntoView({behavior: 'smooth'})
    }
}

  const handleLogoColor = () => {
    if ( isScrolled === true) {
      return "#73EC13";
    } else {
      return "#FFFFFF";
    }

  };

  const handleMenu = () => {
    const menu = document.querySelector(".menu");
    menu.classList.toggle("active");
  };

  return (
    <header className={`paginaUm ${isScrolled ? "scrolled": ""}`}> 
        <div className={`logoMobile items-center hidden`}>
          <Logo  
            color={handleLogoColor()}
          />
        </div>
        <div className={`menuHamburger`}>
          <button onClick={() => handleMenu()}>
            <i className={`line h-[5px] w-full bg-[#1E1E1E] rounded-[2px]`}></i>
            <i className={`line h-[5px] w-full bg-[#1E1E1E] rounded-[2px]`}></i>
            <i className={`line h-[5px] w-full bg-[#1E1E1E] rounded-[2px]`}></i>
          </button>
        </div>
        <nav className= "menu">
            <div className="logo-menu1 flex justify-center items-center">
                <Logo  
                  color={handleLogoColor()}
                />
                {/* <img  src={logo} className= "logo-menu2" alt= "logo" /> */}
            </div>
            <div className={`closeMenu flex w-[30px] justify-self-end`}>
              <button  onClick={() => handleMenu()}>
                  <i className={`line h-[5px] w-full bg-[#1E1E1E] rounded-[2px]`}></i>
                  <i className={`line h-[5px] w-full bg-[#1E1E1E] rounded-[2px]`}></i>
              </button>
            </div>
            <ul className="links">
              <li className="links_item"><a data-id="quem" href= "#quem" className= "pri1" onClick={(event) => scroll(event)}>Quem somos</a></li>
              <li className="links_item"><a data-id="produ" href= "#produ" className= "seg1" onClick={(event) => scroll(event)}>Produtos</a></li>
              <li className="links_item"><a data-id="parce" href= "#parce" className= "ter1" onClick={(event) => scroll(event)}>Parceiros</a></li>
              <li className="links_item"><a data-id="clients" href= "#clients" className= "quar1" onClick={(event) => scroll(event)}>Nossos Clientes</a></li>
            </ul>
        </nav>
      </header>
  );
}