import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogBody, DialogHeader } from '@material-tailwind/react';

export default function SeguroDialog(props) {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setScreenWidth(window.innerWidth);
        });

        return () => {
            window.removeEventListener('resize', () => {
                setScreenWidth(window.innerWidth);
            });
        };
    }, []);

    const dialogContents = [
        {
            name: 'plano_de_saude',
            title: 'Plano de Saúde',
            description: 'Na CHS Seguros, oferecemos planos de saúde que garantem qualidade e cuidado.  Soluções personalizadas para pessoas e/ou empresas de todos os tamanhos, assegurando o bem-estar e a saúde de sua e de seus colaboradores com benefícios exclusivos e condições especiais. Trabalhamos com todas as operadoras/seguradora de saúde do mercado.[/p]Nossa equipe está pronta para auxiliar na escolha e oferecer suporte contínuo. Solicite sua cotação sem compromisso.',
            imageUrl: '/images/plano-de-saude',
            message: 'Olá,%20gostaria%20de%20saber%20mais%20sobre%20o%20Plano%20de%20Saúde%20da%20CHS%20Seguros.',
            contact: '5527988486439',
        },
        {
            name: 'seguro_auto',
            title: 'Seguro Auto',
            description: 'Proteja seu veículo com o Seguro Auto da CHS Seguros! Tenha tranquilidade ao dirigir com uma cobertura completa que inclui proteção contra roubos, acidentes, danos a terceiros e assistência 24 horas. Planos personalizados que se ajustam a suas necessidades e ao seu orçamento. Com a CHS Seguros, você dirige com segurança e confiança, sabendo que está bem amparado em qualquer situação.',
            imageUrl: '/images/seguro-auto',
            message: 'Olá,%20gostaria%20de%20saber%20mais%20sobre%20o%20Seguro%20Auto%20da%20CHS%20Seguros.',
            contact: '5527992463421',
        },
        {
            name: 'plano_odontologico',
            title: 'Plano Odontológico',
            description: 'Cuide do seu sorriso com os Planos Odontológicos da CHS Seguros! Oferecemos coberturas completas para tratamentos dentários, desde consultas de rotina até procedimentos mais complexos, como clareamento estético. Nossos planos incluem ampla rede de profissionais, agendamento facilitado e atendimento de qualidade para toda a empresa ou família. Com valores acessíveis e opções personalizadas, você garante a saúde bucal que merece, com a segurança e confiança que só a CHS Seguros pode oferecer.',
            imageUrl: '/images/plano-odontologico',
            message: 'Olá,%20gostaria%20de%20saber%20mais%20sobre%20o%20Plano%20Odontológico%20da%20CHS%20Seguros.',
            contact: '5527988486439',
        },
        {
            name: 'seguro_de_vida',
            title: 'Seguro de Vida',
            description: 'Garanta a segurança de quem você ama com o Seguro de Vida da CHS. Nossos planos oferecem cobertura completa, incluindo indenizações para invalidez, doenças graves e outros imprevistos, proporcionando tranquilidade para você e sua família. Com opções flexíveis que se adaptam às suas necessidades, a Corretora está aqui para cuidar do seu futuro e garantir a proteção financeira de quem mais importa na sua vida.',
            imageUrl: '/images/seguro-de-vida',
            message: 'Olá,%20gostaria%20de%20saber%20mais%20sobre%20o%20Seguro%20de%20Vida%20da%20CHS%20Seguros.',
            contact: '5527992463421',
        },
        {
            name: 'consorcio',
            title: 'Consórcio',
            description: 'Planeje a conquista dos seus sonhos com o Consórcio da CHS. Seja para a compra de um imóvel, carro ou outros bens, oferecemos uma forma inteligente e sem juros de alcançar seus objetivos. Com prazos flexíveis, parcelas que cabem no seu orçamento e sorteios mensais, você tem mais chances de realizar seus projetos de forma segura e planejada. Conte com a Corretora para transformar seus planos em realidade.',
            imageUrl: '/images/consorcio',
            message: 'Olá,%20gostaria%20de%20saber%20mais%20sobre%20o%20Consórcio%20da%20CHS%20Seguros.',
            contact: '5527992463421',
        },
        {
            name: 'demais_seguros',
            title: 'Demais Seguros',
            description: 'A Corretora CHS oferece uma ampla variedade de seguros para proteger você, sua família e seus bens. Além dos seguros de Auto, Vida e Odontológico, disponibilizamos opções como seguro residencial, empresarial, de viagem, entre outros. Cada seguro é pensado para atender suas necessidades específicas, garantindo a segurança e tranquilidade que você merece. Com a CHS, você conta com soluções personalizadas e a confiança de estar sempre bem protegido.',
            imageUrl: '/images/demais-seguros',
            message: 'Olá,%20gostaria%20de%20saber%20mais%20sobre%20os%20Demais%20Seguros%20da%20CHS%20Seguros.',
            contact: '5527992463421',
        },
    ];
    const { isOpen, handleOpenDialog, dialogName } = props;
    const dialogContent = dialogContents.find(content => content.name === dialogName);
    
    return (
        <>
            <Dialog
                open={isOpen}
                handler={(event) => handleOpenDialog(event)}
                size="xl"
            >
                <DialogBody>
                    <div className={`dialogContainer flex flex-row gap-[15px] items-center`}>
                        <div className={`dialogImageContainer w-[50%] flex ${screenWidth < 600 ? 'hidden' : ''} rounded-[10px] overflow-hidden`}>
                            <img className="object-fill" src={`${dialogContent?.imageUrl}${screenWidth < 768 ? '-vertical' : ''}.jpg`} />
                        </div>
                        <div className={`dialogContent ${screenWidth < 600 ? 'w-[100%]' :  'w-[50%]' } `}>
                            <h2 className={`font-bold text-[32px] flex text-[#1E1E1E]`}>{dialogContent?.title ?? ''}</h2>
                            {dialogContent?.description.includes('[/p]') ? dialogContent?.description.split('[/p]').map((item, index) => {return <p key={index} className="text-[#000]">{item}</p>}) : <p className="text-[#000]">{dialogContent?.description}</p>}
                            <a className="w-[223px] h-[72px] text-[16px] flex justify-center items-center text-[#FFF] bg-gradient-to-r from-[#73EC13] to-[#10AA97] rounded-md mt-5" href={`https://wa.me/${dialogContent?.contact}?text=${dialogContent?.message}`} target="_blank">Entre em contato</a>
                        </div>
                    </div>
                </DialogBody>
            </Dialog>
        </>
    );
};