export default function Logo(props) {
    const { color } = props;
    return (
        <svg className="top-logo" width="281" height="48" viewBox="0 0 281 48" fill={`${color}`} xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_347_157)">
        <path d="M65.864 2.76807H68.656C68.52 5.09607 68.28 7.40807 68.272 9.72807C68.24 18.8401 68.256 27.9521 68.312 37.0641C68.32 38.4561 68.608 39.8401 68.768 41.2801H65.904C66.008 39.6881 66.192 38.1441 66.2 36.6001C66.24 32.4801 66.216 28.3521 66.216 24.2241C66.216 23.8801 66.216 23.5281 66.216 23.1121H42.728C42.968 29.1601 42.392 35.2081 43.104 41.3361C42.512 41.3361 41.752 41.3361 40.992 41.3361C40.712 41.3361 40.432 41.3041 40.08 41.2801C40.992 37.5921 40.656 33.9121 40.68 30.2481C40.72 23.7601 40.72 17.2641 40.68 10.7761C40.664 8.11207 40.472 5.44807 40.352 2.77607H43.128C43.008 4.24807 42.8 5.69607 42.784 7.14407C42.736 11.4241 42.768 15.7121 42.768 20.0001C42.768 20.3441 42.768 20.6881 42.768 21.0881H66.248C66.008 15.0001 66.616 8.89607 65.864 2.78407V2.76807Z"/>
        <path d="M76.384 35.248C76.928 34.528 77.408 33.896 77.976 33.152C79.192 35.776 81.16 37.472 83.704 38.48C88.208 40.256 92.768 40.616 97.296 38.6C101.248 36.84 103.208 32.864 102.288 28.76C101.936 27.168 100.944 26.024 99.664 25.12C97.432 23.544 94.824 22.872 92.264 22.088C89.4 21.216 86.544 20.312 83.688 19.408C83.328 19.296 82.984 19.112 82.64 18.944C76.872 16.128 76.216 8.67204 81.392 4.81604C83.632 3.15205 86.224 2.51204 88.936 2.38404C92.376 2.22404 95.664 2.92805 98.752 4.48804C99.208 4.72005 99.672 4.96005 100.064 5.27204C102.392 7.09605 102.184 6.48805 100.728 8.88004C99.656 7.94405 98.656 6.84804 97.448 6.07204C94.92 4.44805 92.024 3.99204 89.088 4.19204C87.52 4.30404 85.912 4.64805 84.456 5.22405C80.864 6.64805 79.536 10.104 80.176 13.4C80.432 14.712 81.272 15.608 82.32 16.336C84.376 17.768 86.768 18.384 89.128 19.056C92.648 20.056 96.264 20.792 99.528 22.56C102.112 23.968 104.144 25.88 104.48 28.96C105.024 33.904 103.296 38.144 98.104 40.44C93.92 42.288 89.592 42.208 85.256 41C82.224 40.16 79.472 38.808 77.328 36.416C77.008 36.064 76.72 35.68 76.376 35.256L76.384 35.248Z"/>
        <path d="M31.992 9.39192C28.168 5.41592 23.408 4.00792 18.152 4.03992C10.544 4.08792 5.11999 8.12792 2.99199 15.4399C1.35199 21.0559 1.59199 26.6559 4.25599 31.9439C6.79199 36.9679 11.048 39.6239 16.616 40.1199C20.616 40.4719 24.472 39.7759 28.008 37.8399C29.44 37.0559 30.704 35.9599 32.056 34.9919C32.208 35.5359 32.44 36.2239 32.56 36.9279C32.6 37.1519 32.384 37.5359 32.168 37.6719C27.456 40.8079 22.28 42.1679 16.632 41.7359C12.304 41.4079 8.49599 39.8879 5.41599 36.7759C2.31999 33.6559 0.775985 29.7759 0.239985 25.4959C-0.336015 20.9119 0.0719854 16.4239 2.10399 12.2079C4.91199 6.37592 9.63198 3.21592 16.048 2.51992C21.736 1.90392 26.928 3.28792 31.776 6.23192C32.512 6.67992 32.744 7.12792 32.416 7.91992C32.224 8.37592 32.136 8.87992 31.992 9.39192Z"/>
        <path d="M149.088 20.5521L149.92 18.8081C151.088 19.8641 153.08 20.6241 155.096 20.6241C157.808 20.6241 158.968 19.5681 158.968 18.2081C158.968 14.3761 149.472 16.8001 149.472 10.9761C149.472 8.56009 151.352 6.49609 155.472 6.49609C157.312 6.49609 159.216 6.99209 160.512 7.88809L159.776 9.68009C158.408 8.80809 156.864 8.40009 155.472 8.40009C152.808 8.40009 151.664 9.52009 151.664 10.8881C151.664 14.7201 161.16 12.3201 161.16 18.0801C161.16 20.4801 159.232 22.5361 155.088 22.5361C152.688 22.5361 150.336 21.7281 149.088 20.5681V20.5521Z"/>
        <path d="M176.84 20.3921V22.3441H165.464V6.66406H176.528V8.61606H167.704V13.4321H175.568V15.3361H167.704V20.4001H176.84V20.3921Z"/>
        <path d="M192.296 14.416H194.448V20.528C192.88 21.848 190.704 22.52 188.512 22.52C183.696 22.52 180.16 19.136 180.16 14.504C180.16 9.87204 183.696 6.48804 188.56 6.48804C191.048 6.48804 193.152 7.32004 194.584 8.90404L193.192 10.296C191.896 9.04004 190.416 8.48004 188.648 8.48004C185.016 8.48004 182.4 11.008 182.4 14.504C182.4 18 185.024 20.528 188.624 20.528C189.944 20.528 191.176 20.24 192.296 19.52V14.416Z"/>
        <path d="M199.624 15.5761V6.66406H201.864V15.4881C201.864 18.9601 203.456 20.5281 206.28 20.5281C209.104 20.5281 210.712 18.9601 210.712 15.4881V6.66406H212.888V15.5761C212.888 20.1201 210.4 22.5201 206.256 22.5201C202.112 22.5201 199.624 20.1201 199.624 15.5761Z"/>
        <path d="M228.984 22.3441L225.6 17.5281C225.288 17.5521 224.952 17.5761 224.616 17.5761H220.744V22.3441H218.504V6.66406H224.616C228.696 6.66406 231.16 8.72806 231.16 12.1281C231.16 14.5441 229.904 16.2961 227.712 17.0801L231.432 22.3441H228.992H228.984ZM228.92 12.1281C228.92 9.88806 227.416 8.60806 224.552 8.60806H220.744V15.6641H224.552C227.416 15.6641 228.92 14.3681 228.92 12.1281Z"/>
        <path d="M234.544 14.504C234.544 9.91204 238.08 6.48804 242.896 6.48804C247.712 6.48804 251.208 9.89604 251.208 14.504C251.208 19.112 247.672 22.52 242.896 22.52C238.12 22.52 234.544 19.096 234.544 14.504ZM248.968 14.504C248.968 11.032 246.368 8.48004 242.896 8.48004C239.424 8.48004 236.784 11.032 236.784 14.504C236.784 17.976 239.384 20.528 242.896 20.528C246.408 20.528 248.968 17.976 248.968 14.504Z"/>
        <path d="M254.232 20.5521L255.064 18.8081C256.232 19.8641 258.224 20.6241 260.24 20.6241C262.952 20.6241 264.112 19.5681 264.112 18.2081C264.112 14.3761 254.616 16.8001 254.616 10.9761C254.616 8.56009 256.496 6.49609 260.616 6.49609C262.456 6.49609 264.36 6.99209 265.656 7.88809L264.92 9.68009C263.552 8.80809 262.008 8.40009 260.616 8.40009C257.952 8.40009 256.808 9.52009 256.808 10.8881C256.808 14.7201 266.304 12.3201 266.304 18.0801C266.304 20.4801 264.376 22.5361 260.232 22.5361C257.832 22.5361 255.48 21.7281 254.232 20.5681V20.5521Z"/>
        <path d="M134.488 42.288L134.84 41.544C135.336 41.992 136.192 42.32 137.056 42.32C138.216 42.32 138.712 41.872 138.712 41.288C138.712 39.648 134.648 40.68 134.648 38.192C134.648 37.16 135.456 36.272 137.216 36.272C138 36.272 138.816 36.48 139.376 36.864L139.056 37.632C138.472 37.256 137.808 37.088 137.216 37.088C136.072 37.088 135.584 37.568 135.584 38.152C135.584 39.792 139.648 38.768 139.648 41.232C139.648 42.256 138.824 43.144 137.048 43.144C136.024 43.144 135.016 42.8 134.48 42.296L134.488 42.288Z"/>
        <path d="M147.776 41.3759H144.208L143.472 43.0559H142.48L145.52 36.3359H146.472L149.52 43.0559H148.512L147.776 41.3759ZM147.44 40.6079L145.992 37.3199L144.544 40.6079H147.44Z"/>
        <path d="M152.76 40.1599V36.3439H153.72V40.1279C153.72 41.6159 154.4 42.2879 155.608 42.2879C156.816 42.2879 157.504 41.6159 157.504 40.1279V36.3439H158.432V40.1599C158.432 42.1039 157.368 43.1359 155.592 43.1359C153.816 43.1359 152.752 42.1119 152.752 40.1599H152.76ZM156.2 34.5439H157.368L155.752 35.7919H154.888L156.192 34.5439H156.2Z"/>
        <path d="M162.856 36.344H165.688C167.848 36.344 169.312 37.704 169.312 39.704C169.312 41.704 167.848 43.064 165.688 43.064H162.856V36.344ZM165.624 42.224C167.28 42.224 168.352 41.208 168.352 39.704C168.352 38.2 167.288 37.184 165.624 37.184H163.808V42.232H165.624V42.224Z"/>
        <path d="M178.096 42.2239V43.0559H173.224V36.3359H177.96V37.1679H174.184V39.2319H177.552V40.0479H174.184V42.2159H178.096V42.2239Z"/>
        <path d="M192.024 43.16L191.128 42.264C190.544 42.832 189.776 43.128 188.88 43.128C187.52 43.128 186.528 42.408 186.528 41.384C186.528 40.56 187 39.984 188.16 39.32C187.56 38.712 187.352 38.28 187.352 37.784C187.352 36.88 188.064 36.28 189.168 36.28C190.184 36.28 190.84 36.808 190.84 37.68C190.84 38.36 190.44 38.848 189.408 39.432L191.072 41.08C191.256 40.736 191.4 40.328 191.488 39.864L192.224 40.104C192.112 40.696 191.904 41.216 191.64 41.64L192.544 42.544L192.024 43.16ZM190.584 41.736L188.672 39.832C187.736 40.36 187.432 40.752 187.432 41.288C187.432 41.928 188.04 42.36 188.936 42.36C189.576 42.36 190.144 42.152 190.576 41.728L190.584 41.736ZM188.216 37.768C188.216 38.112 188.352 38.36 188.896 38.92C189.776 38.424 190.064 38.112 190.064 37.688C190.064 37.264 189.744 36.952 189.168 36.952C188.56 36.952 188.208 37.288 188.208 37.768H188.216Z"/>
        <path d="M200.608 39.6959C200.608 37.7119 202.12 36.2639 204.168 36.2639C205.2 36.2639 206.104 36.6159 206.72 37.3119L206.096 37.9119C205.576 37.3679 204.944 37.1119 204.208 37.1119C202.696 37.1119 201.568 38.2079 201.568 39.6959C201.568 41.1839 202.688 42.2799 204.208 42.2799C204.944 42.2799 205.576 42.0239 206.096 41.4719L206.72 42.0719C206.104 42.7599 205.2 43.1279 204.16 43.1279C202.128 43.1279 200.608 41.6799 200.608 39.6959Z"/>
        <path d="M209.84 39.6959C209.84 37.7279 211.36 36.2639 213.416 36.2639C215.472 36.2639 216.976 37.7199 216.976 39.6959C216.976 41.6719 215.456 43.1279 213.416 43.1279C211.376 43.1279 209.84 41.6639 209.84 39.6959ZM216.024 39.6959C216.024 38.2079 214.912 37.1119 213.424 37.1119C211.936 37.1119 210.808 38.2079 210.808 39.6959C210.808 41.1839 211.92 42.2799 213.424 42.2799C214.928 42.2799 216.024 41.1839 216.024 39.6959Z"/>
        <path d="M226.672 36.344V43.064H225.888L221.856 38.056V43.064H220.896V36.344H221.68L225.712 41.352V36.344H226.672Z"/>
        <path d="M230.512 42.288L230.864 41.544C231.36 41.992 232.216 42.32 233.08 42.32C234.24 42.32 234.744 41.872 234.744 41.288C234.744 39.648 230.672 40.68 230.672 38.192C230.672 37.16 231.48 36.272 233.24 36.272C234.024 36.272 234.84 36.48 235.4 36.864L235.08 37.632C234.496 37.256 233.832 37.088 233.24 37.088C232.096 37.088 231.608 37.568 231.608 38.152C231.608 39.792 235.672 38.768 235.672 41.232C235.672 42.256 234.848 43.144 233.072 43.144C232.048 43.144 231.04 42.8 230.504 42.296L230.512 42.288Z"/>
        <path d="M238.976 39.6959C238.976 37.7279 240.496 36.2639 242.552 36.2639C244.608 36.2639 246.112 37.7199 246.112 39.6959C246.112 41.6719 244.592 43.1279 242.552 43.1279C240.512 43.1279 238.976 41.6639 238.976 39.6959ZM245.16 39.6959C245.16 38.2079 244.048 37.1119 242.56 37.1119C241.072 37.1119 239.944 38.2079 239.944 39.6959C239.944 41.1839 241.056 42.2799 242.56 42.2799C244.064 42.2799 245.16 41.1839 245.16 39.6959ZM243.144 34.5439H244.312L242.704 35.7919H241.84L243.144 34.5439Z"/>
        <path d="M254.52 43.0559L253.072 40.9919C252.936 40.9999 252.792 41.0079 252.648 41.0079H250.992V43.0479H250.032V36.3279H252.648C254.392 36.3279 255.448 37.2079 255.448 38.6719C255.448 39.7039 254.912 40.4559 253.968 40.7919L255.56 43.0479H254.512L254.52 43.0559ZM254.488 38.6799C254.488 37.7199 253.848 37.1759 252.616 37.1759H250.984V40.1999H252.616C253.848 40.1999 254.488 39.6399 254.488 38.6879V38.6799Z"/>
        <path d="M258.904 39.6959C258.904 37.7119 260.416 36.2639 262.464 36.2639C263.496 36.2639 264.4 36.6159 265.016 37.3119L264.392 37.9119C263.872 37.3679 263.24 37.1119 262.504 37.1119C260.992 37.1119 259.864 38.2079 259.864 39.6959C259.864 41.1839 260.984 42.2799 262.504 42.2799C263.24 42.2799 263.872 42.0239 264.392 41.4719L265.016 42.0719C264.4 42.7599 263.496 43.1279 262.456 43.1279C260.424 43.1279 258.904 41.6799 258.904 39.6959Z"/>
        <path d="M268.824 36.344H269.784V43.064H268.824V36.344Z"/>
        <path d="M273.696 39.6959C273.696 37.7279 275.208 36.2639 277.272 36.2639C279.336 36.2639 280.832 37.7199 280.832 39.6959C280.832 41.6719 279.312 43.1279 277.272 43.1279C275.232 43.1279 273.696 41.6639 273.696 39.6959ZM279.872 39.6959C279.872 38.2079 278.76 37.1119 277.272 37.1119C275.784 37.1119 274.656 38.2079 274.656 39.6959C274.656 41.1839 275.768 42.2799 277.272 42.2799C278.776 42.2799 279.872 41.1839 279.872 39.6959Z"/>
        <path d="M119.392 0H116.192V48H119.392V0Z"/>
        </g>
        <defs>
        <clipPath id="clip0_347_157">
        <rect width="280.832" height="48"/>
        </clipPath>
        </defs>
        </svg>
    );
}