import React from 'react';
import {createRoot} from 'react-dom/client';
import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';
// import getenv from "getenv";

const Maps = () => (
    
        // console.log(getenv('MAPS_API_KEY')),
        <APIProvider apiKey={`AIzaSyDDWHU-PAX3CHJi0AxYkYHxse2qeKxUYm0`} onLoad={() => {console.log('Handle maps')}}>
        <Map
            style={{width: '100%', height: '100%', margin: 'auto', borderRadius: '10px'}}
            center={{lat: -20.315099504696732, lng: -40.29775523150271}}
            zoom={15}

        >
            <Marker position={{lat: -20.315099504696732, lng: -40.29775523150271}} />
        </Map>
        </APIProvider>
    );

    window.addEventListener("DOMContentLoaded", function (e) {
        const root = createRoot(document.getElementById('#maps'));
        root.render(
            <React.StrictMode>
                <Maps />
            </React.StrictMode>
        );
    });

    export default Maps;