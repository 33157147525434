import './App.css';
import cont from './components/img/cont.png';
import cont2 from './components/img/cont2.png'
import card1 from './components/img/pexels-s.jpg';
import card2 from './components/img/card2.jpg';
import card3 from './components/img/card3.png';
import card4 from './components/img/card4.jpg';
import card5 from './components/img/card5.jpg';
import card6 from './components/img/card6.jpg';
import logoVer from './components/img/Logo-verd.svg';
import map from './components/img/Map.png';
import fece from './components/img/facebook.png';
import insta from './components/img/instagram.png';
import linke from './components/img/linkedin-rect.png';
import blocks from './components/img/block.png';
import star from './components/img/Vector.png';
import ffss from './components/img/ffss.png';
import Header from './components/Header/Header';
import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "../node_modules/swiper/swiper-react";
import "../node_modules/swiper/swiper.min.css";
import "../node_modules/swiper/modules/pagination.min.css"
import "../node_modules/swiper/modules/navigation.min.css"
import "./components/styles.css";

import bot1 from './components/img/bot1.png';
import bot2 from './components/img/bot2.png';

import SwiperCore from '../node_modules/swiper/swiper';
import { Pagination,Navigation } from '../node_modules/swiper/modules';
import { Dialog, DialogHeader } from '@material-tailwind/react';
import SeguroDialog from './components/Seguros/Dialog/SeguroDialog';
import Maps from './components/Maps/maps';
import ContactDialog from './components/Contato/Dialog/ContactDialog';
SwiperCore.use([Pagination,Navigation]);

function App() {

  const [initialWidth, setInitialWidth] = useState(window.innerWidth);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogName, setDialogName] = useState('');
  const [openContactDialog, setOpenContactDialog] = useState(false);

  const handleOpenDialog = (event, cardName) => {
    event?.preventDefault();
    setOpenDialog(!openDialog);
    setDialogName(cardName)
  };

  const handleOpenContactDialog = (event) => {
    event?.preventDefault();
    setOpenContactDialog(!openContactDialog);
  }

  return (
    <div className="App">
      <Header />
      <div className='top-box'>
        <div className='top'>
          <div className='poligon-content' style={{maxWidth: initialWidth < 1500 ? '1500px' : initialWidth + 'px', width: '100%', height: '100%', margin: '0 auto'}}>
            <video height="100vh" autoPlay muted playsInline loop>
              <source src={`/videos/travelling.mp4`} type="video/mp4"/>
            </video>
          </div>
        </div>
        <div className='top-text-box'>
          <div className="texto">
            <h1 className="primeiro">Encontre Tranquilidade com <b>Nossos Seguros</b></h1>
            <h2 className="terceiro">Protegendo seu futuro com confiança e dedicação, hoje e sempre</h2>
            <a href="#" className="link3" onClick={(event) => handleOpenContactDialog(event)}>CONTRATE AGORA</a>
          </div>  
        </div>
      </div>
      <SeguroDialog
        isOpen={openDialog}
        handleOpenDialog={() => handleOpenDialog()}
        dialogName={dialogName}
      />

      <ContactDialog
        isOpen={openContactDialog}
        handleOpenContactDialog={() => handleOpenContactDialog()}
      />

      <div className="conteudo2">
        <div className= "box-titulo">
          <div className= "cima">
            <h2 className= "titulo">Nós Cuidamos da sua <span className= "titulo2">saúde e bem estar </span>para você viver com total segurança</h2>

          </div>
        </div>
        <div className= "fotos">
          <img src= {cont} className= "cont" />
          <div className= "caixa" id= "quem"><img src= {cont2} className= "cont2" />
          <div className= "lorem">
            <h2 className= "princi">Quem Somos</h2>
            <p className= "para">Na CHS Seguros, entendemos que cada cliente é único e valorizamos a tranquilidade que podemos oferecer. Com anos de experiência e parcerias com as principais seguradoras e operadoras do país, garantimos proteção e segurança para você e sua família. Nossa equipe dedicada está sempre pronta para oferecer soluções personalizadas que atendam às suas necessidades específicas. Priorizamos a transparência, a confiança e um atendimento de excelência. Escolher a CHS Seguros é optar por um parceiro que coloca seu bem estar em primeiro lugar, cuidando do que é mais importante para você.</p>
          </div>
          </div>
        </div>

      </div>


      <div className= "conteudo3" id= "produ">
        
          <div className= "pag">
            <h2 className= "titulo">Proteja seu <span className= "sg">Futuro </span>com nossos <span className= "qa">Seguros e planos de saúde</span></h2>
          </div>
        <div className= "pags">
          <p className= "em">Invista hoje na segurança do amanhã com nossos seguros e planos de saúde</p>
        </div>
      <div className= "qg1">
      <div className= "qg">
        <div className= "services grid grid-cols-1 xl:grid-cols-3 sm:grid-cols-1 md:grid-cols-2 gap-[10px]">
          <div className="card"><a onClick={(event) => handleOpenDialog(event, 'plano_de_saude')} href='#' className="card-item">PLANO DE SAÚDE</a> <img src= {card1} className="card1" /></div>
          <div className="card"><a onClick={(event) => handleOpenDialog(event, 'seguro_auto')} href='#' className="card-item">SEGURO AUTO</a> <img src= {card2} className="card2" /></div>
          <div className="card"><a onClick={(event) => handleOpenDialog(event, 'plano_odontologico')} href='#' className="card-item">PLANO ODONTOLÓGICO</a> <img src= {card3} className="card3" /></div>
          <div className="card"><a onClick={(event) => handleOpenDialog(event, 'seguro_de_vida')} href='#' className="card-item">SEGURO DE VIDA</a><img src= {card4} className="card4" /></div>
          <div className="card"><a onClick={(event) => handleOpenDialog(event, 'consorcio')} href='#' className="card-item">CONSÓRCIO</a><img src= {card5} className="card5" /></div>
          <div className="card"><a onClick={(event) => handleOpenDialog(event, 'demais_seguros')} href='#' className="card-item">DEMAIS SEGUROS</a><img src= {card6} className="card6" /></div>
        </div>
      </div>
      </div>
    

      </div>
       
       <div className= "conteudo4" id= "parce">
        <div className= "rep">
          <div className= "tit">
            <h2 className= "titulo">Nossos Parceiros em <span className= "pl">Saúde, Odonto e Auto</span></h2>
          </div>
        
        </div>

        <div className= "cao">
         <h2 className= "pcb">Cuidando de você junto dos melhores</h2>
        </div>
        

        <div className= "animacao">
          <div className= "scroll">
            <div className= "log"><img src={'/images/parceiros/unimed.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/odonto-prev.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/amil.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/samp.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/allcare.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/bradesco-saude.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/best-senior.png'} className= "lo" /></div>
            <div className= "log"><img src={'/images/parceiros/unimed.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/odonto-prev.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/amil.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/samp.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/allcare.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/bradesco-saude.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/best-senior.png'} className= "lo" /></div>
            <div className= "log"><img src={'/images/parceiros/unimed.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/odonto-prev.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/amil.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/samp.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/allcare.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/bradesco-saude.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/best-senior.png'} className= "lo" /></div>
            <div className= "log"><img src={'/images/parceiros/unimed.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/odonto-prev.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/amil.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/samp.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/allcare.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/bradesco-saude.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/best-senior.png'} className= "lo" /></div>
          </div>
        </div>

        <div className= "animacao">
          <div className= "scroll">
            <div className= "log2"><img src= {'/images/parceiros/tokio-marine-seguradora.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/sompo.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/mitsui-sumitomo.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/axa.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/hdi-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/porto-seguro.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/liberty-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/azul-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/alfa-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/tokio-marine-seguradora.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/sompo.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/mitsui-sumitomo.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/axa.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/hdi-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/porto-seguro.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/liberty-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/azul-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/alfa-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/tokio-marine-seguradora.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/sompo.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/mitsui-sumitomo.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/axa.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/hdi-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/porto-seguro.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/liberty-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/azul-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/alfa-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/tokio-marine-seguradora.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/sompo.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/mitsui-sumitomo.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/axa.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/hdi-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/porto-seguro.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/liberty-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/azul-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/alfa-seguros.png'} className= "lo2" /></div>
          </div>
        </div>

        <div className= "animacao">
          <div className= "scroll">
            <div className= "log"><img src= {'/images/parceiros/best-senior.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/bradesco-saude.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/allcare.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/samp.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/amil.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/odonto-prev.png'} className= "lo" /></div>
            <div className= "log"><img src={'/images/parceiros/unimed.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/best-senior.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/bradesco-saude.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/allcare.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/samp.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/amil.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/odonto-prev.png'} className= "lo" /></div>
            <div className= "log"><img src={'/images/parceiros/unimed.png'} className= "lo" /></div>  
            <div className= "log"><img src= {'/images/parceiros/best-senior.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/bradesco-saude.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/allcare.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/samp.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/amil.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/odonto-prev.png'} className= "lo" /></div>
            <div className= "log"><img src={'/images/parceiros/unimed.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/best-senior.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/bradesco-saude.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/allcare.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/samp.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/amil.png'} className= "lo" /></div>
            <div className= "log"><img src= {'/images/parceiros/odonto-prev.png'} className= "lo" /></div>
            <div className= "log"><img src={'/images/parceiros/unimed.png'} className= "lo" /></div>    
          </div>
        </div>

        <div className= "animacao">
          <div className= "scroll">
            <div className= "log2"><img src= {'/images/parceiros/alfa-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/azul-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/liberty-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/porto-seguro.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/sompo.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/hdi-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/axa.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/mitsui-sumitomo.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/tokio-marine-seguradora.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/alfa-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/azul-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/liberty-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/porto-seguro.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/sompo.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/hdi-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/axa.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/mitsui-sumitomo.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/tokio-marine-seguradora.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/alfa-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/azul-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/liberty-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/porto-seguro.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/sompo.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/hdi-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/axa.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/mitsui-sumitomo.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/tokio-marine-seguradora.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/alfa-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/azul-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/liberty-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/porto-seguro.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/sompo.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/hdi-seguros.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/axa.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/mitsui-sumitomo.png'} className= "lo2" /></div>
            <div className= "log2"><img src= {'/images/parceiros/tokio-marine-seguradora.png'} className= "lo2" /></div>
          </div>
        </div>

       </div>

       <div className= "conteudo5" id= "clients">
        <div className= "titi">
          <h2 className= "coc">O que <span className= "verd">Nossos Clientes </span>dizem sobre nós</h2>
        </div>

        <div className= "baix">
          <h2 className= "coc2">Experiências reais de quem confia na nossa proteção e cuidado</h2>
        </div>

        
          
        <div className="container5">

          <Swiper
            slidesPerView={3}
            // centeredSlides={true}
            loop={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 20
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 20
              }
            }}
            spaceBetween={20}
            navigation={{
              nextEl: '.my-next-button',
              prevEl: '.my-prev-button'
            }}
            
          >
            <SwiperSlide>
              <div className="swiper-slide"> 
                <div className= "star">
                  <img src= {star} />
                  <img src= {star} />
                  <img src= {star} />
                  <img src= {star} />
                  <img src= {ffss} />
                </div>
                <p className= "mogli">“Estou muito satisfeita com o atendimento da CHS! Desde o primeiro contato, fui tratada com atenção e todas as minhas dúvidas foram esclarecidas. Contratei o seguro de vida e me sinto muito mais tranquila sabendo que minha família está protegida. Recomendo a Corretora de olhos fechados!”</p>  
                <div className= "ffs">
                  {/* <div className= "boll"></div> */}
                  <div className= "nameR">Maria S.</div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide><div className="swiper-slide">
              <div className= "star">
                <img src= {star} />
                <img src= {star} />
                <img src= {star} />
                <img src= {star} />
                <img src= {ffss} />
              </div>
              <p className= "mogli">“Fiz o seguro auto com a CHS e foi a melhor escolha que eu poderia ter feito. Além de um preço justo, o atendimento é rápido e eficiente. Recentemente precisei acionar a assistência 24 horas e fui atendido prontamente. Agradeço a equipe da Corretora pelo excelente serviço!”</p>  
              <div className= "ffs">
                {/* <div className= "boll"></div> */}
                <div className= "nameR">João R.</div>
              </div>
            </div>
            </SwiperSlide>
            <SwiperSlide><div className="swiper-slide"> <div className= "star">
                <img src= {star} />
                <img src= {star} />
                <img src= {star} />
                <img src= {star} />
                <img src= {ffss} />
              </div>
              <p className= "mogli">“Decidi contratar um consórcio com a CHS e estou muito feliz com o acompanhamento que recebi. As informações foram claras desde o início, e a equipe sempre esteve disponível para me ajudar. Já fui contemplada e estou prestes a realizar o sonho da casa própria. Muito obrigada, CHS!”</p>  
              <div className= "ffs">
                {/* <div className= "boll"></div> */}
                <div className= "nameR">Fernanda A.</div>
              </div></div></SwiperSlide>
              <SwiperSlide><div className="swiper-slide"> <div className= "star">
                <img src= {star} />
                <img src= {star} />
                <img src= {star} />
                <img src= {star} />
                <img src= {ffss} />
              </div>
              <p className= "mogli">“Escolhi a CHS para o meu seguro residencial e não poderia estar mais satisfeito. A equipe foi super atenciosa e me ajudou a encontrar a melhor cobertura para a minha casa. Recentemente precisei usar o seguro e o atendimento foi impecável. A Corretora realmente se preocupa com os clientes.”</p>  
              <div className= "ffs">
                {/* <div className= "boll"></div> */}
                <div className= "nameR">Carlos M.</div>
              </div></div></SwiperSlide>
              <SwiperSlide><div className="swiper-slide"> <div className= "star">
                <img src= {star} />
                <img src= {star} />
                <img src= {star} />
                <img src= {star} />
                <img src= {ffss} />
              </div>
              <p className= "mogli">“Decidi contratar um consórcio com a CHS e estou muito feliz com o acompanhamento que recebi. As informações foram claras desde o início, e a equipe sempre esteve disponível para me ajudar. Já fui contemplada e estou prestes a realizar o sonho da casa própria. Muito obrigada, CHS!”</p>  
              <div className= "ffs">
                {/* <div className= "boll"></div> */}
                <div className= "nameR">Fernanda A.</div>
              </div></div></SwiperSlide>
              <SwiperSlide><div className="swiper-slide"> <div className= "star">
                <img src= {star} />
                <img src= {star} />
                <img src= {star} />
                <img src= {star} />
                <img src= {ffss} />
              </div>
              <p className= "mogli">“Contratei o plano odontológico da CHS e fiquei impressionada com a qualidade do serviço. A rede de profissionais é excelente, e o atendimento sempre foi rápido e eficiente. Agora, tenho a tranquilidade de saber que minha saúde bucal está em boas mãos. Agradeço à Corretora por todo o suporte.”</p>  
              <div className= "ffs">
                {/* <div className= "boll"></div> */}
                <div className= "nameR">Lúcia T.</div>
              </div></div></SwiperSlide>      
          </Swiper>

         
          <div className= "bott">
            <div className="my-prev-button">
                <button className= "butao"><img src= {bot1} alt="Prev" /></button>
            </div>
            <div className="my-next-button">
              <button className= "butao"><img src= {bot2} alt="Next" /></button>
            </div>
          </div>
          
        
    
          
        </div>
    </div>
    <div className= "conteudo6">
        <div className= "continer6">
          <img src= {logoVer} className= "logoVer" />
          <div id="maps" className={`w-[80%] mx-auto rounded-[10px] mt-[50px] h-[370px] overflow-hidden`}>
            <Maps />
          </div>
          <div className= "pog">
            {/* <a href= '#'><div className= "social-icon"><img src= {fece} className= "fece" /></div></a> */}
            <a href= 'https://www.instagram.com/chs.seguros' target='_blank'><div className= "social-icon"><img src= {insta} className= "insta" /></div></a>
            <a href= 'https://www.linkedin.com/company/chs-seguros/' target='_blank'><div className= "social-icon"><img src= {linke} className= "linke" /></div></a>
          </div>

          <div className= "pol">
            <h2 className= "titulo">Quer saber mais? Fale com um de nossos atendentes, estamos aqui para ajudar.</h2>
          </div>
          
          <a href= '#' className= "flex justify-center items-center w-[229px] h-[72px] bg-gradient-to-r from-[#73EC13] to-[#0BD8BF] text-white rounded-lg mx-auto mt-5" onClick={(event) => handleOpenContactDialog(event)}>CONTRATE AGORA</a>

        </div>

       </div>
    <footer className= "fim"><p className= "fim2">Copyright © CHS - SEGUROS E CONSÓRCIOS | Todos os Direitos Reservados</p></footer>
  </div>
  );
}

export default App;
