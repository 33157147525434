import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogBody, DialogHeader } from '@material-tailwind/react';
import styles from './contact-dialog.module.css';

export default function ContactDialog(props) {

    const { isOpen, handleOpenContactDialog } = props;
    return (
        <Dialog 
            open={isOpen}
            handler={(event) => handleOpenContactDialog(event)}
        >
            <DialogHeader>
                <h2 className={`text-2xl font-bold text-[#1E1E1E]`}>Fale Conosco</h2>
            </DialogHeader>
            <DialogBody>
                <div className={`flex flex-row items-center`}>
                    <div className={`flex flex-col items-center gap-4`}>
                        <p className={`flex text-justify text-[#1E1E1E]`}>Estamos aqui para ajudar você! Entre em contato conosco para saber mais sobre nossos serviços e como podemos atender às suas necessidades. Nossa equipe está pronta para oferecer suporte e orientação personalizada, garantindo a melhor solução para você e sua empresa. Fale conosco e descubra o que a CHS Seguros pode fazer por você!</p>
                        <div className={`${styles.buttonContent} flex flex-row gap-5`}>
                            <button className={`w-[223px] h-[72px] text-[16px] flex justify-center items-center text-[#FFF] bg-gradient-to-r from-[#73EC13] to-[#10AA97] rounded-md mt-5`} ripple="light" onClick={() => window.open(`https://wa.me/5527988486439?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20os%Planos%20da%20CHS%20Seguros.`, '_blank')}>Planos</button>
                            <button className={`w-[223px] h-[72px] text-[16px] flex justify-center items-center text-[#FFF] bg-gradient-to-r from-[#73EC13] to-[#10AA97] rounded-md mt-5`} ripple="light" onClick={() => window.open(`https://wa.me/5527992463421?text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20os%Seguros%20da%20CHS%20Seguros.`, '_blank')}>Seguros</button>
                        </div>
                    </div>
                </div>
            </DialogBody>
        </Dialog>
    )
}